import { Box } from '@mui/material'
import React from 'react'
import LottieLoader from 'react-lottie-loader'
import loadinAnimationData from '../resources/loader.json'

const Loader = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LottieLoader
        style={{ width: '80px' }}
        animationData={loadinAnimationData}
      />
    </Box>
  )
}

export default Loader
